export const CALCULATOR_CONTENT = {
  title: 'Skorzystaj z kalkulatora, żeby&nbsp;poznać Twoją ofertę finansowania',
  currency: 'PLN',
  thumbWidth: 30,
  amount: {
    min: 5000,
    max: 150000,
    step: 500,
    start: 30000,
    percent: 0.175
  },
  dates: {
    startRepaymentTerm: 12,
    startRepaymentMaxTerm: 1
  }
}
