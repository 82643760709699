<template>
  <aside :class="'aside-buttons' + (isActive ? ' active' : '')">
    <div :class="'buttons-container' + (isOpen ? ' box-open' : '')">
      <button id="buttonScrollToCalculator" class="scrollUp-button" @click="scrollToCalculator" title="Idź do kalkulatora"></button>
      <button id="buttonScrollToSection" class="scrollUp-button" @click="scrollToSection" title="Wróć do poprzedniej sekcji"></button>
      <button id="buttonScrollToTop" class="scrollUp-button" @click="scrollToTop" title="Wróć do początku"></button>
    </div>
    <button :class="'handleAside-button' + (isOpen ? ' open' : '')" @click="handleAside">
      <span class="button-elem"></span>
      <span class="button-elem"></span>
      <span class="button-elem"></span>
    </button>
  </aside>
</template>

<script>

export default {
  name: 'ButtonScrollUp',
  data () {
    return {
      isActive: false,
      isOpen: false
    }
  },
  methods: {
    handleAside () {
      this.isOpen = !this.isOpen
    },
    scrollToCalculator () {
      const [calculator] = this.$parent.$children.filter(({ $el }) => $el.id === 'calculator-section')
      const top = calculator.$el.offsetTop
      window.scrollTo(0, top)
    },
    scrollToSection () {
      const pagePosition = window.pageYOffset
      const sectionsArray = this.$parent.$children.filter(({ $el }) => $el.id !== '')
      const beforeArray = sectionsArray.filter(({ $el }) => $el.offsetTop < pagePosition)
      const sectionBefore = beforeArray[beforeArray.length - 1]
      const sectionBeforeOffset = sectionBefore.$el.offsetTop
      sectionBeforeOffset && window.scrollTo(0, sectionBeforeOffset)
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    handleScroll () {
      window.scrollY > window.innerHeight ? (this.isActive = true) : (this.isActive = false)
    }
  },
  mounted () {
    window.addEventListener('resize', this.onResize)
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>
