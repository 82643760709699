<template>
  <div>
    <p>
      <span class="required" v-if="agreement.required">Pole wymagane. </span>Oświadczam, że zapoznałem się z informacjami o administratorach danych osobowych:<br/><br/>
      Administratorem Pana/Pani danych osobowych jest Brutto sp. z o.o. z siedzibą w Warszawie.
      Dane osobowe przetwarzane będą w celu założenia konta oraz udostępnienia funkcjonalności
      serwisu <a href="https://www.brutto.pl/">Brutto.pl</a>. Rozwiń, aby uzyskać więcej informacji na temat przetwarzania danych przez
      Brutto sp. z o.o. z siedzibą w Warszawie. Rozwiń, aby uzyskać więcej informacji na
      temat przetwarzania danych przez Brutto sp. z o.o. z siedzibą w Warszawie <a class="more" v-if="!showMoreWarsaw" @click="toggleShowMoreWarsaw($event)">[więcej]</a>
    </p>
    <div class="consent-full-text" v-if="showMoreWarsaw">
      <ol>
        <li>
          Administratorem Pana/Pani danych osobowych jest Brutto sp. z o.o. z siedzibą w Warszawie („Brutto”) przy ul. Inżynierskiej 8, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy dla M.St. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS 0000631388, NIP 7123316804, REGON 365116501.
        </li>
        <li>
          Brutto, jako operator serwisu Brutto.pl ("Serwis"), przetwarza dane osobowe w celu:
          <ul>
            <li>
              założenia konta oraz udostępnienia funkcjonalności Serwisu (zgodnie z regulaminem Serwisu dostępnym pod adresem (https://www.brutto.pl/regulamin/) pozwalających na złożenie wniosku do PragmaGO S.A. z siedzibą w Katowicach o finansowanie na podstawie zaliczek przychodowych oraz na wykonanie umowy zawartej w przypadku pozytywnego rozpatrzenia wniosku przez PragmaGO z siedzibą w Katowicach (art. 6 ust. 1 lit. b) RODO – przetwarzanie jest niezbędne do zawarcia i wykonania umowy);
            </li>
            <li>
              marketingu bezpośredniego usług własnych Brutto, dochodzenia roszczeń, obsługi reklamacyjnej, podjęcia innych czynności niezbędnych dla prawidłowego działania Serwisu (art. 6 ust. 1 lit. f) RODO – prawnie uzasadniony interes administratora danych);
            </li>
            <li>
              przesyłania informacji handlowych dotyczących Brutto w przypadku udzielenia zgody (art. 6 ust. 1 lit. a) RODO – zgoda podmiotu danych osobowych).
            </li>
          </ul>
        </li>
        <li>
          Jeżeli jest to niezbędne do realizacji powyższych celów, dane osobowe mogą być przekazywane:
          <ul>
            <li>
              podmiotom uprawnionym do ich otrzymania na podstawie przepisów prawa;
            </li>
            <li>
              podmiotowi rozpatrującemu wniosek o finansowanie na podstawie zaliczek przychodowych, tj. PragmaGO S.A. z siedzibą w Katowicach;
            </li>
            <li>
              podmiotom przetwarzającym dane osobowe na zlecenie Brutto.
            </li>
          </ul>
        </li>
        <li>
          Dane osobowe będą przetwarzane każdorazowo przez okres niezbędny dla realizacji celu przetwarzania. Dane osobowe mogą być przechowywane przez cały czas aktywności konta w Serwisie (trwania umowy), po czym będą przechowywane jako dane z atrybutem archiwalne do czasu przedawnienia ewentualnych roszczeń. Dane marketingowe przetwarzane na podstawie zgody będą przetwarzane najpóźniej do momentu cofnięcia zgody.
        </li>
        <li>
          Każdej osobie przysługuje:
          <ul>
            <li>
              prawo do cofnięcia zgody (w przypadku udzielenia zgody marketingowej) bez wpływu na zgodność przetwarzania do momentu cofnięcia zgody,
            </li>
            <li>
              prawo dostępu do swoich danych osobowych,
            </li>
            <li>
              prawo do sprostowania danych osobowych,
            </li>
            <li>
              prawo do wniesienia sprzeciwu,
            </li>
            <li>
              prawo do przenoszenia danych w przypadkach określonych w przepisach RODO;
            </li>
            <li>
              prawo do usunięcia lub ograniczenia przetwarzania danych osobowych;
            </li>
            <li>
              prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem;
            </li>
            <li>
              prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, jeśli osoba, której dane dotyczą uzna, że przetwarzanie danych przez Brutto narusza przepisy RODO lub przepisy krajowe. Adresy, pod którymi można składać skargi do Prezesa UODO:
              <ul>
                <li>
                  listownie: ul. Stawki 2, 00-193 Warszawa,
                </li>
                <li>
                  przez elektroniczną skrzynkę podawczą dostępną na stronie: https://www.uodo.gov.pl/pl/p/kontakt,
                </li>
                <li>
                  telefonicznie: (22) 531 03 00.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          Brutto wyznaczył Inspektora Ochrony Danych, Pawła Latkowskiego, którego adres mailowy: iod@brutto.pl stanowi adres kontaktowy do zgłaszania wszelkich spraw związanych z przetwarzaniem danych osobowych użytkowników Serwisu. Użytkownik Serwisu może zgłosić chęć realizacji swoich uprawnień związanych z przetwarzaniem swoich danych osobowych także na inne adresy do korespondencji opublikowane na stronach internetowych Serwisu, w tym do korespondencji listownej, jak również drogą telefoniczną, niemniej Brutto, mając na względzie odpowiednio szybkie podjęcie odpowiednich do charakteru sprawy czynności merytorycznych, rekomenduje korzystanie z adresu e-mail podanego powyżej.
        </li>
        <li>
          Podanie danych jest niezbędne w celu założenia konta w Serwisie oraz w celu złożenia wniosku do PragmaGO S.A. z siedzibą w Katowicach o uzyskanie finansowania na podstawie zaliczek przychodowych.
        </li>
        <li>
          Dane osobowe, w zakresie, w jakim nie pochodzą bezpośrednio od osoby, której dotyczą, zostały Brutto udostępnione przez Autopay S.A. z siedzibą w Sopocie lub przez „Integratora” w rozumieniu regulaminu korzystania z systemu płatności online Autopay.
        </li>
      </ol>
      <a class="more" v-if="showMoreWarsaw" @click="toggleShowMoreWarsaw($event)">[mniej]</a>
    </div>
    <p class="mt-20">
      Administratorem Pana/Pani danych osobowych jest Autopay S.A. z siedzibą w Sopocie.
      Dane osobowe przetwarzane będą w celu umożliwienia skorzystania z funkcjonalności serwisu
      Brutto.pl polegającej na uzyskaniu finansowania. Rozwiń, aby uzyskać więcej informacji na
      temat przetwarzania danych przez Autopay S.A. z siedzibą w Sopocie <a class="more" v-if="!showMoreSopot" @click="toggleShowMoreSopot($event)">[więcej]</a>
    </p>
    <div class="consent-full-text" v-if="showMoreSopot">
      <ol>
        <li>
          Administratorem Pana/Pani danych osobowych jest Autopay Spółka Akcyjna z siedzibą w Sopocie przy ul. Powstańców Warszawy 6, 81-718 Sopot, wpisaną do Krajowego Rejestru Sądowego – rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy Gdańsk- Północ w Gdańsku, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS 0000320590, o kapitale zakładowym 2 000 000 PLN (w całości wpłaconym), o numerze NIP: 585-13-51-185, dalej „Autopay”.
        </li>
        <li>
          Autopay przetwarza dane osobowe w celu:
          <ul>
            <li>
              Udostepnienia danych osobowych Brutto Sp. z o.o. w celu utworzenia, przypisania konta w serwisie Brutto.pl ( art. 6 ust. 1 lit. a) RODO;
            </li>
            <li>
              Wyliczenia należności oraz spłaty należności na rzecz PragmaGo w ramach funkcjonalności Systemu Płatności Online Autopay na (art. 6 ust. 1 lit. f oraz art. 6 ust. 1 lit. a RODO);
            </li>
            <li>
              marketingu bezpośredniego usług własnych Autopay S.A., dochodzenia roszczeń, obsługi reklamacyjnej, podjęcia innych czynności niezbędnych dla prawidłowego działania (art. 6 ust. 1 lit. f) RODO );
            </li>
          </ul>
        </li>
        <li>
          Jeżeli jest to niezbędne do realizacji powyższych celów, dane osobowe mogą być przekazywane:
          <ul>
            <li>
              podmiotom uprawnionym do ich otrzymania na podstawie przepisów prawa;
            </li>
            <li>
              podmiotowi rozpatrującemu wniosek o finansowanie na podstawie zaliczek przychodowych, tj. PragmaGO S.A. z siedzibą w Katowicach;
            </li>
            <li>
              operatorowi serwisu Brutto.pl, Brutto Sp. z o.o.
            </li>
            <li>
              podmiotom przetwarzającym dane osobowe na zlecenie Autopay S.A.
            </li>
          </ul>
        </li>
        <li>
          Dane osobowe będą przetwarzane każdorazowo przez okres niezbędny dla realizacji celu przetwarzania oraz do czasu wygaśnięcia ewentualnych roszczeń. Dane osobowe mogą być przechowywane dłużej niż wskazano, jeżeli przepisy prawa tego wymagają.
        </li>
        <li>
          Każdej osobie przysługuje:
          <ul>
            <li>
              prawo do cofnięcia zgody,
            </li>
            <li>
              prawo dostępu do swoich danych osobowych,
            </li>
            <li>
              prawo do sprostowania danych osobowych,
            </li>
            <li>
              prawo do wniesienia sprzeciwu,
            </li>
            <li>
              prawo do przenoszenia danych w przypadkach określonych w przepisach RODO;
            </li>
            <li>
              prawo do usunięcia lub ograniczenia przetwarzania danych osobowych;
            </li>
            <li>
              prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem;
            </li>
            <li>
              prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych,
            </li>
          </ul>
        </li>
        <li>
          Autopay wyznaczyła Inspektora Ochrony Danych, którego adres mailowy: odo@autopay.pl stanowi adres kontaktowy do zgłaszania wszelkich spraw związanych z przetwarzaniem danych osobowych. Zgłoszeń można dokonywać również pisemnie na adres siedziby spółki wskazany w ust. 1.
        </li>
        <li>
          Pozostałe informacje dotyczące przetwarzania danych osobowych przez Autopay S.A. znajdują się Polityce Prywatności Autopay S.A.
        </li>
      </ol>
      <a class="more" v-if="showMoreSopot" @click="toggleShowMoreSopot($event)">[mniej]</a>
    </div>
    <p class="mt-20">
      Administratorem Pana/Pani danych osobowych jest PragmaGO S.A. z siedzibą w Katowicach.
      Dane osobowe przetwarzane będą w celu rozpatrzenia wniosku o finansowanie na podstawie
      zaliczek przychodowych. Rozwiń, aby uzyskać więcej informacji na temat przetwarzania
      danych przez PragmaGO S.A. z siedzibą w Katowicach <a class="more" v-if="!showMoreKatowice" @click="toggleShowMoreKatowice($event)">[więcej]</a>
    </p>
    <div class="consent-full-text" v-if="showMoreKatowice">
      <p>
        Administratorem Pani/Pana danych osobowych jest PragmaGO S.A. z siedzibą w Katowicach, adres: ul. Brynowska 72, 40-584 Katowice, KRS 0000267847. We wszelkich sprawach dotyczących ochrony danych osobowych prosimy o kontakt z inspektorem ochrony danych: IOD, PragmaGO SA, ul. Brynowska 72, 40-584 Katowice i/lub iod@pragmago.pl.
      </p>
      <p>
        PragmaGO otrzyma część dotyczących Pani/Pana danych osobowych za pomocą niniejszego formularza, część zaś (imię (imiona), nazwisko, dane kontaktowe (w tym: adres zamieszkania, adres korespondencyjny, numer telefonu, adres e-mail), informacje dotyczące usług, z których korzysta klient, w tym dane finansowe dotyczące miesięcznych obrotów klientów (wartość obrotu ogółem, z podziałem na liczbę transakcji (bez danych osobowych kupujących), dane dotyczące zawartej Umowy Ramowej, w tym faktu jej rozwiązania, wygaśnięcia, zmiany warunków) od Autopay S.A. z siedzibą w Sopocie, ul. Powstańców Warszawy 6, 81-718 Sopot, KRS 0000320590. Pani/Pana dane osobowe będą przetwarzane w celu podjęcia działań dążących do zawarcia umowy na Pani/Pana żądanie, a także zawarcia i wykonania Umowy; ustalenia, dochodzenia lub obrony przed ewentualnymi roszczeniami mogącymi mieć związek z realizacją Umowy; wykonania obowiązków oraz realizacji uprawnień wynikających z przepisów ustawy o przeciwdziałaniu praniu pieniędzy oraz finansowaniu terroryzmu w szczególności w celu identyfikowania i oceny ryzyka związanego z praniem pieniędzy i finansowaniem terroryzmu, stosowania środków bezpieczeństwa obejmujących między innymi identyfikację klienta i weryfikację jego tożsamości. Więcej informacji o celach i podstawach prawnych przetwarzania danych osobowych w regulaminie oraz polityce prywatności.
      </p>
      <p>
        Dodatkowo, PragmaGO, działając wspólnie i w porozumieniu z Pragma Faktor Sp. z o.o. z siedzibą w Katowicach, KRS 0000316505 (dalej: Pragma Faktor), będzie przetwarzał dane osobowe w celu prowadzenia działań marketingowych PragmaGO i Pragma Faktor, będących współadministratorami danych przetwarzanych w tym celu. Współadministratorzy wyznaczyli punkt kontaktowy we wszelkich sprawach związanych ze współadministrowaniem danych osobowych: Inspektor Ochrony Danych PragmaGO, dostępny pod adresem e-mail: iod@pragmago.pl lub korespondencyjnie pod adresem ul. Brynowska 72, 40-584 Katowice.
      </p>
      <p>
        Podmiotowi danych przysługuje prawo dostępu do treści swoich danych osobowych oraz prawo ich sprostowania, usunięcia lub ograniczenia przetwarzania, prawo do przenoszenia danych oraz prawo wniesienia sprzeciwu wobec ich przetwarzania, a także prawo wniesienia skargi do organu nadzorczego – Prezesa Urzędu Ochrony Danych Osobowych. W przypadkach, w których przetwarzanie dokonywane jest na podstawie zgody oraz w przypadku wyrażenia zgód na otrzymywanie informacji handlowych drogą elektroniczną, przysługuje prawo wycofania zgody w dowolnym momencie, jednak nie wypływa ono na zgodność z prawem przetwarzania, którego dokonanego przed wycofaniem zgody.
        <a class="more" v-if="showMoreKatowice" @click="toggleShowMoreKatowice($event)">[mniej]</a>
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AdminInfo',
  props: ['agreement'],
  data () {
    return {
      showMoreWarsaw: false,
      showMoreSopot: false,
      showMoreKatowice: false
    }
  },
  methods: {
    toggleShowMoreWarsaw (event) {
      event.preventDefault()
      this.showMoreWarsaw = !this.showMoreWarsaw
    },
    toggleShowMoreSopot (event) {
      event.preventDefault()
      this.showMoreSopot = !this.showMoreSopot
    },
    toggleShowMoreKatowice (event) {
      event.preventDefault()
      this.showMoreKatowice = !this.showMoreKatowice
    }
  }
}
</script>
