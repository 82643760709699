export class DateClass {
  static setYear (years) {
    return new Date(new Date().setFullYear(new Date().getFullYear() + years)).toISOString().slice(0, 10).split('-').reverse().join('.')
  }

  static setMaxDate (years, months) {
    const date = new Date()
    date.setMonth(date.getMonth() + months)
    date.setFullYear(date.getFullYear() + years)
    return date.toISOString().slice(0, 10).split('-').reverse().join('.')
  }
}
