export const SIMULATION_CONTENT = {
  title: 'Kliknij w przycisk pod wykresem, żeby zobaczyć przykładowe scenariusze spłat',
  simulation: [
    {
      id: 1,
      amount: 5000,
      dayPercent: '5'
    },
    {
      id: 2,
      amount: 25000,
      dayPercent: '10'
    },
    {
      id: 3,
      amount: 50000,
      dayPercent: '20'
    }
  ]
}
