<template>
  <section id="calculator-section" class="section">
    <section-title :text="title" />
    <div id="calculator">
      <div class="calculator-actions">
        <div class="input-container">
          <input id="calculator-input" type="number" :min="min" :max="max" :step="step" v-model="calculatorValue" @input="validateInputSetValue($event.target.value)" @change="validateInputChangeValue($event.target.value)" class="calculator-input">
          <span class="calculator-span">&nbsp;{{currency}}</span>
        </div>
        <div class="slider-container">
          <input id="calculator-slider" type="range" ref="slider" :style="{ '--rangeWidth': setRange() }" :min="min" :max="max" :step="step" v-model="calculatorValue" class="calculator-slider">
          <label for="calculator-slider" class="slider-text">
            <span class="info-text">{{min}} {{currency}}</span>
            <span class="info-text">{{max}} {{currency}}</span>
          </label>
        </div>
      </div>
      <div class="table-container">
        <table>
          <tr>
            <td>Otrzymujesz na konto</td>
            <td class="calculator-text-box bold"><span id="calculator-amount">{{setCalculateAmount()}}</span></td>
          </tr>
          <tr>
            <td>Łączny koszt</td>
            <td class="calculator-text-box"><span id="calculator-cost">{{setCalculateCost()}}</span></td>
          </tr>
          <tr>
            <td>Do spłaty</td>
            <td class="calculator-text-box"><span id="calculator-sum">{{setCalculateSum()}}</span></td>
          </tr>
          <tr>
            <td>Dzienna spłata z Twoich przychodów<br/><span class="light-text">(Procent poznasz po sprawdzeniu limitu)</span></td>
            <td class="calculator-text-box"><span></span><span class="calculator-span">X%</span></td>
          </tr>
        </table>
        <table>
          <tr>
            <td>Prognozowana data spłaty<br/><span class="light-text">Spłata przez 12 miesięcy</span></td>
            <td class="calculator-text-box"><span  id="calculator-date" class="calculator-span">{{ setRepaymentDateShorter() }}</span></td>
          </tr>
          <tr>
            <td>Maksymalny termin spłaty<br/><span class="light-text">W ramach tego samego kosztu, otrzymujesz <br/> dodatkowy miesiąc na spłatę</span> </td>
            <td class="calculator-text-box"><span  id="calculator-max-date" class="calculator-span">{{setRepaymentDateMax()}}</span></td>
          </tr>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import SectionTitle from '../common/SectionTitle'
import { CostCalculatorClass, NumberFormatterClass } from '../../class/calculator.class'
import { DateClass } from '../../class/date.class'

export default {
  name: 'CalculatorSection',
  components: {
    SectionTitle
  },
  props: ['content'],
  data () {
    return {
      slider: null,
      thumb: this.content.thumbWidth,
      title: this.content.title,
      currency: this.content.currency,
      min: this.content.amount.min,
      max: this.content.amount.max,
      step: this.content.amount.step,
      calculatorValue: this.content.amount.start,
      calculatorPercent: this.content.amount.percent,
      repaymentTerm: this.content.dates.startRepaymentTerm,
      repaymentMaxTerm: this.content.dates.startRepaymentMaxTerm
    }
  },
  methods: {
    validateInputSetValue (value) {
      if (value > this.max) {
        this.calculatorValue = this.max
      }
    },
    validateInputChangeValue (value) {
      if (value < this.min) {
        this.calculatorValue = this.min
      }
      if (value > this.max) {
        this.calculatorValue = this.max
      }
    },
    setCalculateAmount () {
      if (this.calculatorValue >= this.min) {
        return NumberFormatterClass.formatAmount(this.calculatorValue)
      }
      if (this.calculatorValue < this.min) {
        return 0
      }
    },
    setCalculateCost () {
      if (this.calculatorValue >= this.min) {
        const cost = new CostCalculatorClass(this.calculatorValue, this.calculatorPercent)
        return NumberFormatterClass.formatAmount(cost.cost)
      }
      if (this.calculatorValue < this.min) {
        return 0
      }
    },
    setCalculateSum () {
      if (this.calculatorValue >= this.min) {
        const cost = new CostCalculatorClass(this.calculatorValue, this.calculatorPercent)
        return NumberFormatterClass.formatAmount(cost.sum)
      }
      if (this.calculatorValue < this.min) {
        return 0
      }
    },
    setRange () {
      if (this.slider) {
        const position = (this.calculatorValue - this.min) / (this.max - this.min)
        const thumbCorrect = this.thumb * (position - 0.5) * -1
        const sliderWidth = this.$refs.slider.offsetWidth
        const thumbPosition = Math.round((position * sliderWidth) - this.thumb / 3 + thumbCorrect)
        return thumbPosition + 'px'
      }
    },
    setRepaymentDateShorter () {
      return DateClass.setYear(1)
    },
    setRepaymentDateMax () {
      return DateClass.setMaxDate(1, this.repaymentMaxTerm)
    },
    onResize () {
      this.slider = this.$refs.slider.offsetWidth
      this.setRange()
    }
  },
  mounted () {
    this.slider = this.$refs.slider.offsetWidth
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>
