<template>
  <div :class="'simulation-container' + setActiveClass()">
    <div class="simulation-data">
      <div class="bar-container" ref="barBox">
        <span :style="{ 'height': setBarAmountHeight() }"></span>
        <span :style="{ 'height': setBarCostHeight() }"></span>
      </div>
      <div class="table-container">
        <div class="table-box">
          <p class="info-text">Wysokość finansowania:</p>
          <p class="article-text"><span>{{ setFormatAmount() }}</span></p>
        </div>
        <div class="table-box">
          <p class="info-text">Łączny koszt:</p>
          <p class="article-text"><span>{{ setSimulationCost() }}</span></p>
        </div>
        <div class="table-box">
          <p class="info-text">Do spłaty: </p>
          <p class="article-text"><span>{{ setSimulationSum() }}</span></p>
        </div>
        <div class="table-box">
          <p class="info-text">Prognozowana data spłaty: </p>
          <p class="info-text"><span>{{ setRepaymentDateShorter() }}</span><span class="simulation-span"></span></p>
        </div>
        <div class="table-box">
          <p class="info-text">Na spłatę pobieramy:</p>
          <p class="info-text"><span>{{ dayPercent }}</span><span class="simulation-span">% od dziennej sprzedaży</span></p>
        </div>
      </div>
    </div>
    <p v-if="isScenarioActive()" class="simulation-button--disable">Scenariusz dziennych spłat poniżej</p>
    <button v-if="!isScenarioActive()" class="simulation-button" @click="setActiveScenario">sprawdź scenariusz</button>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { CostCalculatorClass, NumberFormatterClass } from '../../class/calculator.class'
import { DateClass } from '../../class/date.class'
import { CALCULATOR_CONTENT } from '../../const/calculator.const'

export default {
  name: 'SimulationBox',
  props: ['simulation'],
  data () {
    return {
      scenarioId: this.simulation.id,
      amount: this.simulation.amount,
      dayPercent: this.simulation.dayPercent,
      maxAmount: CALCULATOR_CONTENT.amount.max,
      percent: CALCULATOR_CONTENT.amount.percent,
      costAmount: null,
      barBoxHeight: null,
      repaymentTerm: CALCULATOR_CONTENT.dates.startRepaymentTerm,
      repaymentMaxTerm: CALCULATOR_CONTENT.dates.startRepaymentMaxTerm
    }
  },
  computed: {
    ...mapState({
      activeScenario: state => state.scenarios.scenario
    })
  },
  methods: {
    ...mapMutations([
      'SET_SCENARIO_ACTIVE',
      'SET_SCENARIO_AMOUNT',
      'SET_SCENARIO_PERCENT'
    ]),
    setActiveScenario () {
      this.SET_SCENARIO_AMOUNT(this.amount)
      this.SET_SCENARIO_ACTIVE(this.scenarioId)
      this.SET_SCENARIO_PERCENT(this.dayPercent)
      if (window.innerWidth < 992) {
        this.goToScenario()
      }
    },
    isScenarioActive () {
      return this.activeScenario === this.scenarioId
    },
    goToScenario () {
      const top = this.$parent.$el.nextSibling.offsetTop
      window.scrollTo(0, top)
    },
    setActiveClass () {
      return this.activeScenario === this.scenarioId ? ' activeScenario' : ''
    },
    setRepaymentDateShorter () {
      return DateClass.setYear(1)
    },
    setFormatAmount () {
      return NumberFormatterClass.formatAmount(this.amount)
    },
    setSimulationCost () {
      const cost = new CostCalculatorClass(this.amount, this.percent)
      this.costAmount = cost.cost
      return NumberFormatterClass.formatAmount(cost.cost)
    },
    setSimulationSum () {
      const sum = new CostCalculatorClass(this.amount, this.percent)
      return NumberFormatterClass.formatAmount(sum.sum)
    },
    getBarBoxHeight () {
      this.barBoxHeight = this.$refs.barBox.offsetHeight
    },
    setBarAmountHeight () {
      const barPercent = parseInt(this.amount) / this.maxAmount
      const barHeight = this.barBoxHeight * barPercent
      return barHeight + 'px'
    },
    setBarCostHeight () {
      const barPercent = parseInt(this.costAmount) / this.maxAmount
      const barHeight = this.barBoxHeight * barPercent
      return barHeight + 'px'
    },
    setScenarioData () {
      this.activeScenario === this.scenarioId && this.SET_SCENARIO_PERCENT(this.dayPercent)
      this.activeScenario === this.scenarioId && this.SET_SCENARIO_AMOUNT(this.amount)
    }
  },
  mounted () {
    this.setScenarioData()
    this.getBarBoxHeight()
  }
}
</script>
