<template>
  <div>
    <p>
      <span class="required" v-if="agreement.required">Pole wymagane. </span>Udzielam zgody Autopay S.A. z siedzibą w Sopocie
      na udostępnienie Brutto sp. z o.o. z siedzibą w Warszawie moich danych osobowych
      (numer NIP i adres e-mail zarejestrowany w Systemie) celem utworzenia
      konta w Serwisie <a href="https://www.brutto.pl/">www.brutto.pl</a> <a class="more" v-if="!showMore" @click="toggleShowMore($event)">[więcej]</a>
    </p>
    <div class="consent-full-text" v-if="showMore">
      <p>
        Jeżeli wyrazisz tę zgodę, Brutto sp. z o.o. z siedzibą w Warszawie uzyska od Autopay S.A. Twój numer NIP oraz adres e-mail i zarejestruje dla Ciebie konto w serwisie <a href="https://www.brutto.pl/">www.brutto.pl</a> z wykorzystaniem tych danych. Brak wyrażenia zgody uniemożliwia Autopay S.A. udostępnienie Twojego adresu e-mail oraz numeru NIP do Brutto sp. z o.o. z siedzibą w Warszawie i tym samym rejestrację Twojego konta w serwisie <a href="https://www.brutto.pl/">www.brutto.pl</a>, co jest niezbędne dla złożenia wniosku do PragmaGO o finansowanie na podstawie zaliczek przychodowych.
        <a class="more" v-if="showMore" @click="toggleShowMore($event)">[mniej]</a>
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DataProcessing',
  props: ['agreement'],
  data () {
    return {
      showMore: false
    }
  },
  methods: {
    toggleShowMore (event) {
      event.preventDefault()
      this.showMore = !this.showMore
    }
  }
}
</script>
