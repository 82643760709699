export const SCENARIOS_CONTENT = {
  maxGain: 4350,
  scenario: [
    {
      id: 1,
      dayGain: 2000
    },
    {
      id: 2,
      dayGain: 1500
    },
    {
      id: 3,
      dayGain: 3400
    },
    {
      id: 4,
      dayGain: 0
    },
    {
      id: 5,
      dayGain: 3600
    },
    {
      id: 6,
      dayGain: 2600
    },
    {
      id: 7,
      dayGain: 1500
    }
  ]
}
