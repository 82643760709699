<template>
  <dialog id="agreements" :open="isVisible" @keydown.27="closeDialog" tabindex="-1" aria-modal="true" aria-labelledby="dialog-title">
    <div class="dialog-container">
      <div class="dialog-loader" v-if="isLoading">
        <span></span>
      </div>
      <button class="close-button openDialog" @click="closeDialog"></button>
      <div>
        <el-form ref="agreementsForm"
                 class="consent-list"
                 novalidate
                 :model="form">
          <el-form-item class="check-all">
            <el-checkbox v-model="checkAll" @change="handleCheckAll">Zaznacz wszystkie zgody</el-checkbox>
          </el-form-item>
          <el-form-item v-for="consent in requiredConsentList"
                        :key="consent.id"
                        :prop="consent.id"
                        :class="{ 'is-error': consent.required && !form.consents[consent.id] && form.isFormSubmit }">
            <div class="checkbox-wrapper">
              <el-checkbox :id="consent.id" v-model="form.consents[consent.id]">
                <span v-if="consent.required" class="required" style="color: red;">*</span>
                <agreement-types :agreement="consent"></agreement-types>
              </el-checkbox>
            </div>
          </el-form-item>
          <div v-if="consentList && consentList.length">
            <div class="additional-connsents">
              <el-form-item v-for="consent in consentList"
                            :key="consent.id"
                            :prop="consent.id">
                <div class="checkbox-wrapper not-required">
                  <el-checkbox :id="consent.id" v-model="form.consents[consent.id]">
                    <agreement-types :agreement="consent"></agreement-types>
                  </el-checkbox>
                </div>
              </el-form-item>

              <el-form-item v-for="consent in onlyLabels" :key="consent.id">
                <agreement-types :agreement="consent"></agreement-types>
              </el-form-item>
            </div>
          </div>
          <el-form-item class="submit">
            <el-button type="primary" @click="submit('agreementsForm')" :disabled="isProcessing">Wyślij <i v-if="isProcessing" class="el-icon-loading"></i></el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </dialog>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { ENDPOINTS } from '../../const/endpoints.const'
import { Raven } from 'vue-raven'
import { ErrorHandler } from '../../class/error-handler.class'
import axiosInstance from '../../api/axios'
import AgreementTypes from './AgreementTypes'

const FORM_MODEL = {
  consents: {},
  isFormSubmit: false
}

export default {
  name: 'AgreementsDialog',
  components: { AgreementTypes },
  computed: {
    ...mapState({
      isVisible: state => state.agreements.dialog,
      isProcessing: state => state.agreements.isProcessing,
      isLoading: state => state.agreements.isLoading,
      agreements: state => state.agreements.data,
      user: state => state.agreements.user
    }),
    ...mapGetters({
      requiredConsentList: 'getRequiredConsentList',
      consentList: 'getConsentList',
      onlyLabels: 'getTextIfNoCheckbox'
    })
  },
  data () {
    return {
      form: FORM_MODEL,
      checkAll: false
    }
  },
  created () {
    if (this.requiredConsentList && this.requiredConsentList.length) {
      this.requiredConsentList.forEach(field => {
        this.$set(this.form.consents, field.id, false)
      })
    }
    if (this.consentList && this.consentList.length) {
      this.consentList.forEach(field => {
        this.$set(this.form.consents, field.id, false)
      })
    }
  },
  methods: {
    ...mapMutations([
      'SET_DIALOG_ACTIVE',
      'SET_AGREEMENTS_PROCESSING'
    ]),
    handleCheckAll () {
      this.requiredConsentList.forEach(consent => {
        this.$set(this.form.consents, consent.id, this.checkAll)
      })
      this.consentList.forEach(consent => {
        this.$set(this.form.consents, consent.id, this.checkAll)
      })
    },
    consentCheckboxValidation () {
      let valid = true

      for (const key in this.form.consents) {
        let required = false

        this.requiredConsentList.forEach(field => {
          if (field.id === key && field.required) {
            required = true
            return false
          }
        })

        if (required && !this.form.consents[key]) {
          valid = false
        }
      }

      return valid
    },
    submit (formName) {
      this.form.isFormSubmit = true

      this.$refs[formName].validate(valid => {
        if (!this.consentCheckboxValidation()) {
          valid = false
        }

        if (!valid) {
          return false
        }

        const formData = Object.assign({},
          { agreements: this.form.consents },
          { hash: this.$route.params.hash })

        this.SET_AGREEMENTS_PROCESSING(true)
        axiosInstance.post(ENDPOINTS.register, formData)
          .then(response => {
            window.location.href = response.data.ssoLoginUrl
          })
          .catch(error => {
            ErrorHandler.handleError(error)
            Raven.captureMessage('AgreementsDialog.vue submitHandler error', {
              extra: {
                data: formData,
                error: error
              }
            })
          })
          .finally(() => {
            this.closeDialog()
            this.SET_AGREEMENTS_PROCESSING(false)
          })
      })
    },
    closeDialog () {
      this.SET_DIALOG_ACTIVE(false)
    },
    isDataSet () {
      return new Promise((resolve) => {
        const handler = setInterval(() => {
          if (this.agreements) {
            clearInterval(handler)
            resolve()
          }
        }, 100)
      })
    }
  }
}
</script>
