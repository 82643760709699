<template>
  <div>
    <p>
      <span class="required" v-if="agreement.required">Pole wymagane. </span>
      Wyrażam zgodę na otrzymywanie drogą elektroniczną informacji handlowych
      dotyczących Brutto sp. z o.o. z siedzibą w Warszawie, na mój adres e-mail
      udostępniony przez Autopay S.A. z siedzibą w Sopocie.
    </p>
  </div>
</template>

<script>

export default {
  name: 'Marketing',
  props: ['agreement']
}
</script>
