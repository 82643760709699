<template>
  <div>
    <p>
      <span class="required" v-if="agreement.required">Pole wymagane. </span> Akceptuję <a href="https://www.brutto.pl/regulamin/">Regulamin Serwisu Brutto.pl</a> z jego wszystkimi postanowieniami oraz potwierdzam zapoznanie się z Polityką Prywatności Brutto sp. z o.o.
    </p>
  </div>
</template>

<script>

export default {
  name: 'Regulations',
  props: ['agreement']
}
</script>
