<template>
  <div>
    <p>
      <span class="required" v-if="agreement.required">Pole wymagane. </span> Wyrażam zgodę na przekazywanie przez Autopay S.A. z siedzibą w Sopocie do Brutto sp. z o.o. z siedzibą w Warszawie informacji oraz dokumentów dotyczących zastosowanych przez Autopay S.A. z siedzibą w Sopocie środków bezpieczeństwa finansowego w zakresie zawarcia i realizacji umowy w przedmiocie świadczenia usług płatniczych przez Autopay S.A., zgodnie z przepisami ustawy z dnia 1 marca 2018 r. o przeciwdziałaniu praniu pieniędzy i finansowaniu terroryzmu.&nbsp;<a class="more" v-if="!showMore" @click="toggleShowMore($event)">[więcej]</a>
    </p>
    <div class="consent-full-text" v-if="showMore">
      <p>
        Jeżeli wyrazisz tę zgodę, Autopay S.A. będzie uprawniona do przekazywania do Brutto sp. z o.o. z siedzibą w Warszawie informacji oraz dokumentów dotyczących identyfikacji i weryfikacji Twojej tożsamości (w tym m.in. imiona i nazwiska, numery PESEL i NIP). Brak wyrażenia zgody uniemożliwia Autopay S.A. udostępnienie Twoich danych, a tym samym realizację umowy o udzielenie finansowania.
        <a class="more" v-if="showMore" @click="toggleShowMore($event)">[mniej]</a>
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SecurityData',
  props: ['agreement'],
  data () {
    return {
      showMore: false
    }
  },
  methods: {
    toggleShowMore (event) {
      event.preventDefault()
      this.showMore = !this.showMore
    }
  }
}
</script>
