<template>
  <div>
    <p>
      <span class="required" v-if="agreement.required">Pole wymagane. </span> Wyrażam zgodę na udostępnienie przez Autopay S.A. z siedzibą w Sopocie do Brutto sp. z o.o. z siedzibą w Warszawie moich danych, w tym danych osobowych oraz danych finansowych, przetwarzanych przez Autopay S.A. z siedzibą w Sopocie w ramach Systemu Płatności Online Autopay, na podstawie zawartej przeze mnie umowy ramowej o świadczenie usług płatniczych, w tym informacji objętych tajemnicą płatniczą, o której mowa w ustawie z dnia 19 sierpnia 2011 r. o usługach płatniczych, w celu realizacji umowy o udzielenie finansowania.
      <a class="more" v-if="!showMore" @click="toggleShowMore($event)">[więcej]</a>
    </p>
    <div class="consent-full-text" v-if="showMore">
      <p>
        Jeżeli wyrazisz tę zgodę, Brutto sp. z o.o. z siedzibą w Warszawie uzyska od Autopay S.A. Twoje dane osobowe: imię, nazwisko, NIP, numer telefonu, adres e-mail, numer rachunku bankowego, adres strony internetowej sklepu, oraz dane finansowe: dane o obrotach w cyklach miesięcznych, liczbę i wartość transakcji za ostatnie 12 miesięcy. Brak wyrażenia zgody uniemożliwia Autopay S.A. udostępnienie Twoich danych, a tym samym realizację umowy o udzielenie finansowania.
        <a class="more" v-if="showMore" @click="toggleShowMore($event)">[mniej]</a>
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PersonalData',
  props: ['agreement'],
  data () {
    return {
      showMore: false
    }
  },
  methods: {
    toggleShowMore (event) {
      event.preventDefault()
      this.showMore = !this.showMore
    }
  }
}
</script>
