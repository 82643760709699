<template>
  <div>
    <regulations :agreement="agreement" v-if="isRegulations()" />
    <data-processing :agreement="agreement" v-if="isDataProcessing()" />
    <integrator-processing :agreement="agreement" v-if="isIntegratorProcessing()" />
    <marketing :agreement="agreement" v-if="isMarketing()" />
    <security-data :agreement="agreement" v-if="isSecurityData()" />
    <personal-data :agreement="agreement" v-if="isPersonalData()" />
    <admin-info :agreement="agreement" v-if="isAdminInfo()" />
  </div>
</template>

<script>

import { AGREEMENTS } from '../../const/agreements.const'
import Regulations from './text/Regulations'
import DataProcessing from './text/DataProcessing'
import IntegratorProcessing from './text/IntegratorProcessing'
import Marketing from './text/Marketing'
import SecurityData from './text/SecurityData'
import PersonalData from './text/PersonalData'
import AdminInfo from './text/AdminInfo'
export default {
  name: 'AgreementTypes',
  components: {
    AdminInfo,
    PersonalData,
    SecurityData,
    Marketing,
    IntegratorProcessing,
    DataProcessing,
    Regulations
  },
  props: ['agreement'],
  methods: {
    isRegulations () {
      return this.agreement.name === AGREEMENTS.REGULATIONS
    },
    isDataProcessing () {
      return this.agreement.name === AGREEMENTS.DATA_PROCESSING
    },
    isIntegratorProcessing () {
      return this.agreement.name === AGREEMENTS.INTEGRATOR_PROCESSING
    },
    isMarketing () {
      return this.agreement.name === AGREEMENTS.MARKETING
    },
    isSecurityData () {
      return this.agreement.name === AGREEMENTS.SECURITY_DATA
    },
    isPersonalData () {
      return this.agreement.name === AGREEMENTS.PERSONAL_DATA
    },
    isAdminInfo () {
      return this.agreement.name === AGREEMENTS.ADMIN_INFO
    }
  }
}
</script>
