export class CostCalculatorClass {
  constructor (amount, percent) {
    this.amount = parseInt(amount, 10)
    this.percent = parseFloat(percent)
  }

  get cost () {
    return this.calcCost()
  }

  get sum () {
    return this.calcSum()
  }

  calcCost () {
    return Math.floor(this.amount * this.percent)
  }

  calcSum () {
    return Math.floor(this.amount + (this.amount * this.percent))
  }
}

export class NumberFormatterClass {
  static formatAmount (value) {
    return value ? Number(value).toLocaleString() + ' PLN' : ''
  }
}
