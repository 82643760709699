<template>
  <div class="scenario-container">
    <div class="bar-container" ref="barBox">
      <span ref="bar1" :style="{ 'height': setBarDayCostHeight(), 'width': setBarDayCostWidth() }">
        <span v-if="barDayGainHeight || barDayGainWidth" class="bar-text">{{ dayCost }} PLN</span>
      </span>
      <span :style="{ 'height': setBarDayGainHeight(), 'width': setBarDayGainWidth() }">
        <span v-if="barDayGainHeight || barDayGainWidth" class="bar-text">{{ dayGain }} PLN</span>
      </span>
      <p class="bar-null" v-if="dayGain === 0">Brak sprzedaży i&nbsp;brak spłaty</p>
    </div>
    <div class="title-container">
      <p class="article-text light-text">{{ scenario.id }} dzień</p>
    </div>
  </div>
</template>

<script>
import { SCENARIOS_CONTENT } from '../../const/scenarios.const'
import { mapState } from 'vuex'

export default {
  name: 'ScenarioBox',
  props: ['scenario'],
  data () {
    return {
      windowWidth: null,
      barBoxHeight: null,
      barBoxWidth: null,
      barDayGainWidth: null,
      barDayGainHeight: null,
      maxGain: SCENARIOS_CONTENT.maxGain,
      dayGain: this.scenario.dayGain,
      dayCost: null
    }
  },
  computed: {
    ...mapState({
      percent: state => state.scenarios.percent
    })
  },
  methods: {
    getBarBoxSize () {
      this.barBoxHeight = this.$refs.barBox.offsetHeight
      this.barBoxWidth = this.$refs.barBox.offsetWidth
    },
    onResize () {
      this.windowWidth = window.innerWidth
      this.getBarBoxSize()
    },
    setBarDayCostHeight () {
      if (this.windowWidth >= 992) {
        const barHeight = this.barDayGainHeight * (this.percent / 100)
        this.dayCost = this.dayGain * (this.percent / 100)
        return barHeight + 'px'
      }
    },
    setBarDayCostWidth () {
      if (this.windowWidth < 992) {
        const barWidth = this.barDayGainWidth * (this.percent / 100)
        this.dayCost = this.dayGain * (this.percent / 100)
        return barWidth + 'px'
      }
    },
    setBarDayGainHeight () {
      if (this.windowWidth >= 992) {
        const barPercent = parseInt(this.dayGain) / this.maxGain
        const barHeight = this.barBoxHeight * barPercent
        this.barDayGainHeight = barHeight
        return barHeight + 'px'
      }
    },
    setBarDayGainWidth () {
      if (this.windowWidth < 992) {
        const barPercent = parseInt(this.dayGain) / this.maxGain
        const barWidth = this.barBoxWidth * barPercent
        this.barDayGainWidth = barWidth
        return barWidth + 'px'
      }
    }
  },
  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize)
    this.getBarBoxSize()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>
