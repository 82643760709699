<template>
  <div v-if="canShowBtn()" :class="['button-container', addingClass]">
    <button class="primary-button openDialog"
            :disabled="isProcessing()"
            @click="openDialog"
            @keydown.27="closeDialog">
      Chcę bezpłatnie poznać ofertę <i class="el-icon-loading" v-if="isProcessing()"></i>
    </button>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'ButtonOpenDialog',
  props: ['addingClass'],
  data () {
    return {
      isHash: this.$route.params.hash,
      btnClicked: false
    }
  },
  methods: {
    ...mapMutations([
      'SET_DIALOG_ACTIVE'
    ]),
    isProcessing () {
      return this.isLoading && this.btnClicked
    },
    isDataSet () {
      return new Promise((resolve) => {
        const handler = setInterval(() => {
          if (!this.isLoading) {
            clearInterval(handler)
            resolve()
          }
        }, 100)
      })
    },
    async openDialog () {
      this.btnClicked = true
      if (this.isLoading) {
        await this.isDataSet()
      }
      this.SET_DIALOG_ACTIVE(true)
    },
    closeDialog () {
      this.btnClicked = false
      this.SET_DIALOG_ACTIVE(false)
    },
    canShowBtn () {
      return this.isHash && !this.isHashError && !this.isLoading
    }
  },
  computed: {
    ...mapState({
      isHashError: state => state.agreements.isHashError,
      isLoading: state => state.agreements.isLoading
    })
  }
}
</script>
