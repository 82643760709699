<template>
  <div class="title-container">
    <h2 class="section-title" v-html="text"></h2>
  </div>
</template>

<script>
export default {
  name: 'SectionTitle',
  props: ['text']
}
</script>
