<template>
  <div>
    <p>
      <span class="required" v-if="agreement.required">Pole wymagane. </span>Wyrażam zgodę na udostępnienie przez {{ agreement.params.INTEGRATOR_NAME }} („Integrator”) do Brutto sp. z o.o. z siedzibą w Warszawie moich danych finansowych, przetwarzanych przez Integratora, w celu realizacji umowy o udzielenie finansowania
      <a class="more" v-if="!showMore" @click="toggleShowMore($event)">[więcej]</a>
    </p>
    <div class="consent-full-text" v-if="showMore">
      <p>
        Twoje dane finansowe: dane o obrotach w cyklach miesięcznych, liczbę i wartość transakcji za ostatnie 12 miesięcy. Brak wyrażenia zgody uniemożliwia udostępnienie Twoich danych, a tym samym realizację umowy o udzielenie finansowania.
        <a class="more" v-if="showMore" @click="toggleShowMore($event)">[mniej]</a>
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'IntegratorProcessing',
  props: ['agreement'],
  data () {
    return {
      showMore: false
    }
  },
  methods: {
    toggleShowMore (event) {
      event.preventDefault()
      this.showMore = !this.showMore
    }
  }
}
</script>
