import { mapState } from 'vuex'
import { CALCULATOR_CONTENT } from '../const/calculator.const'
import { SIMULATION_CONTENT } from '../const/simulation.const'
import { SCENARIOS_CONTENT } from '../const/scenarios.const'

export default {
  computed: {
    ...mapState({
      isVisible: (state) => state.agreements.dialog
    })
  },
  data () {
    return {
      calculator: CALCULATOR_CONTENT,
      simulation: SIMULATION_CONTENT,
      scenarios: SCENARIOS_CONTENT
    }
  }
}
