<template>
  <section id="simulation-section" class="section">
    <section-title :text="title" />
    <article id="simulation" class="article">
      <simulation-box v-for="(item, index) in content.simulation" :key="index" :simulation="item"/>
    </article>
  </section>
</template>

<script>
import SectionTitle from '../common/SectionTitle'
import SimulationBox from './SimulationBox'

export default {
  name: 'SimulationSection',
  components: {
    SimulationBox,
    SectionTitle
  },
  props: ['content'],
  data () {
    return {
      title: this.content.title
    }
  }
}
</script>
